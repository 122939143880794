import React from 'react';
import { graphql, PageProps } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = Queries.IndexPageQuery;

const IndexPage = ({ data }: PageProps<Props>) => (
  <Layout>
    <BackgroundImage
      fluid={(data.background?.childImageSharp?.fluid as FluidObject) ?? undefined}
      className="flex grow bg-top py-3"
      style={{ backgroundPosition: '' }}
    >
      <div className="m-auto w-full bg-gray-50 bg-opacity-75 p-4 shadow-lg sm:w-auto sm:max-w-3xl sm:rounded-lg sm:p-6">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            Welcome!
          </h2>
          <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
            {data.site?.siteMetadata.description}
          </p>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
);

export const Head = () => <SEO title="Home" />;

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage {
    site {
      siteMetadata {
        description
      }
    }
    background: file(relativePath: { eq: "backgrounds/IMG_3662.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
